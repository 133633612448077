import { useNavigate } from "react-router-dom";

const SettingSidebar = (props) => {
    const navigation = useNavigate();

    return (
        <aside className="relative w-[13rem] w-min-[13rem] w-max-[13rem] border-e bg-white">
            <div className="px-2 py-3 mb-2 border-y cursor-pointer" onClick={() => navigation('/dashboard')}>
                <div className="flex gap-3 items-center justify-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15 6l-6 6l6 6"></path>
                    </svg>
                    <span className="font-medium text-xs">Keluar Dari Pengaturan</span>
                </div>
            </div>
            <div className="px-4 py-3 cursor-pointer mt-2 hover:bg-slate-100" onClick={() => navigation('/setting/user')}>
                <div className="flex gap-3 items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-red-900" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0"></path>
                        <path d="M6 21v-2a4 4 0 0 1 4 -4h2.5"></path>
                        <path d="M19.001 19m-2 0a2 2 0 1 0 4 0a2 2 0 1 0 -4 0"></path>
                        <path d="M19.001 15.5v1.5"></path>
                        <path d="M19.001 21v1.5"></path>
                        <path d="M22.032 17.25l-1.299 .75"></path>
                        <path d="M17.27 20l-1.3 .75"></path>
                        <path d="M15.97 17.25l1.3 .75"></path>
                        <path d="M20.733 20l1.3 .75"></path>
                    </svg>
                    <span className="font-medium text-xs">Pengaturan User</span>
                </div>
            </div>
            <div className="px-4 py-3 cursor-pointer hover:bg-slate-100" onClick={() => navigation('/setting/personil')}>
                <div className="flex gap-3 items-center text-sm">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-red-900" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M15.004 18.401a1.724 1.724 0 0 0 -1.329 1.282c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.079 .262 1.495 1.305 1.248 2.17"></path>
                        <path d="M9 12a3 3 0 1 0 6 0a3 3 0 0 0 -6 0"></path>
                        <path d="M19 16v3"></path>
                        <path d="M19 22v.01"></path>
                    </svg>
                    <span className="font-medium text-xs">Pengaturan Personil</span>
                </div>
            </div>
        </aside>
    );
}

export default SettingSidebar;